.contact-us-container {
  background-image: radial-gradient(circle, rgb(0, 0, 0),rgb(0, 0, 0));
  padding: 20px;
}

@font-face {
  font-family: 'Zing';
  src: url('../Home/font.woff2') format('woff2'),
       url('../Home/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.outlet {
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to bottom right, gold, darkgoldenrod);
  padding: 20px;
  margin: 10px;
  border-radius: 0px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 255, 0.5);
  font-family: 'Zing',Arial,sans-serif;
}


h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

p > svg {
  margin-right: 8px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  position: relative;
  padding-left: 1.25em; /* Updated value to 'em' unit */
  margin-bottom: 0.3125em;
}

