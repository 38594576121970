.Insta-logo {
    position: absolute;
    top:15px;
    right:15px;
    width: 70px;
    height: auto;
    

   }
.App {
    text-align: center;
  
  }
  
  .App-logo {
    height: 80vmin;
    pointer-events: none;
    margin-top: -50px;
  
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      
    }
  }

  @font-face {
    font-family: 'Zing';
    src: url('./font.woff2') format('woff2'),
         url('./font.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  .App-header {
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .button {
    display: inline-block;
    padding: 10px 20px;
    background-image: linear-gradient(to bottom right, gold, darkgoldenrod);
    color: black;
    font-family: 'Zing',Arial, sans-serif;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 0;
    outline: none;
    transition: background-color 0.3s ease;
    margin-top: 30px;
    margin-left: 15px;
  }
  
  .button:hover,.button:active{
    background-image: linear-gradient(to bottom right, darkgoldenrod, gold);
    color: black;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  